import React, { Component } from 'react'
import { Link } from "gatsby"



import './newPlayer.css'
import './page3.css'




export default class newPlayer extends Component {


  
  

    
    constructor() {

      // parse
     
        
      
  
        super();
        // Don't call this.setState() here!
        this.newState = {}
        // var id=0
        // firebase.db.collection("songs").get().then(snapshot => {
        
          
          
  
        //   snapshot.forEach(doc => {
        //     this.newState[id] = {plays : doc.data().plays,
        //                           likes : doc.data().likes}
        //     console.log(doc.data())
         
        //     id++
        //   })})
        //   console.log(this.newState)
        //   for (let x in this.newState) {
        //     console.log(x)
        //   }
        this.state = { songs :[
          {
            path: 'https://keysforlifeproductions.b-cdn.net/Ninu%20Aradhinthumu%20Yessiah.mp3',
            displayName: 'Ninu Aradhinthumu Yessiah',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/1.%20NEEVU%20THODAI%20HYMN%20PREVIEW.mp3',
            displayName: 'Neevu Thodai Hymn',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/2.%20NEE%20CHERANAMULE%20HYMN%20PREVIEW.mp3',
            displayName: 'Nee Cheranamule Hymn',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          // {
          //   path: 'https://firebasestorage.googleapis.com/v0/b/the-legacy-b3f2f.appspot.com/o/3.%20RANDI%20UTSAHINCHI%20INSTRUMENTAL%20PREVIEW.mp3?alt=media&token=b6d052b1-a614-4ee5-af53-67a96b3599d5',
          //   displayName: 'Randi Utsahinchi Instrumental',                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
          //   artist: 'Solomon Gollapalle',
          //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          // },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/4.%20HALLELUJAH%20STUTHI%20MAHIMA%20PREVIEW%20.mp3',
            displayName: 'Hallelujah Stuthi Mahima',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/5.%20INSTRUMENTAL%20BREAK%20%20PREVIEW%20.mp3',
            displayName: 'Instrumental Break',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/6.%20RANDI%20YEHOVANU%20GURCHI%20PREVIEW.mp3',
            displayName: 'Randi Yehova Gurchi',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          {
            path: 'https://keysforlifeproductions.b-cdn.net/7.%20DEVA%20NEE%20KROAKU%20NA%20PRANAMU%20PREVIEW.mp3',
            displayName: 'Deva Nee Koraku Na Pranamu',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          },
          // {
          //   path: 'https://cdn.keysforlifeproductions.org/8.%20%20NINU%20ARADHINTHUMU%20YESIAH%20PREVIEW.mp3',
          //   displayName: 'Ninu Aradhinthumu Yesaiah',
          //   artist: 'Solomon Gollapalle',
          //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          // },
          // {
          //   path: 'https://cdn.keysforlifeproductions.org/9.%20YESU%20RAKTHAMU%20PREVIEW.mp3',
          //   displayName: 'Yesu Rakthamu',
          //   artist: 'Solomon Gollapalle',
          //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          // }
          ] ,
         
        };
        

        
      
      
      

      }
   
      
     
    
    componentDidMount = () => {
      this.dataState = false;

      

        // for (let item in items) {
        //   console.log(item)
        //   newState.push({
        //     id:item,
        //     plays: items[item].plays,
        //     likes: items[item].likes
        //   })
        // }
        // this.setState({
        //   data: this.newState
        // })
        // console.log(this.newState[0])
        // console.log(this.state.data[1].likes)
        // this.dataState = true
      
      
      
     
    

        const image = document.querySelector('#cover');
        const title = document.getElementById('title');
        const artist = document.getElementById('artist');
        const music = document.querySelector('audio');
        const currentTimeEl = document.getElementById('current-time');
        const durationEl = document.getElementById('duration');
        const progress = document.getElementById('progress');
        const progressContainer = document.getElementById('progress-container');
        const prevBtn = document.getElementById('prev');
        const playBtn = document.getElementById('play');
        const nextBtn = document.getElementById('next');     
        const listSong = document.getElementsByClassName('listSong');      
        const creditsToggle = document.getElementById('next-index');  
        this.credits = true;
        
     
                                                                                                                           /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
        

        // Music
        const full_songs=
        [
          {
            path: 'https://firebasestorage.googleapis.com/v0/b/the-legacy-b3f2f.appspot.com/o/Ninu%20Aradhinthumu%20Yessiah.mp3?alt=media&token=c1fa1389-2554-4dcc-a740-3c8afb21cecd',
            displayName: 'Ninu Aradhinthumu Yessiah',
            artist: 'Solomon Gollapalle',
            cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
          }
        ]
const songs = [
  {
    path: 'https://keysforlifeproductions.b-cdn.net/Ninu%20Aradhinthumu%20Yessiah.mp3',
    displayName: 'Ninu Aradhinthumu Yessiah',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/1.%20NEEVU%20THODAI%20HYMN%20PREVIEW.mp3',
    displayName: 'Neevu Thodai Hymn',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/2.%20NEE%20CHERANAMULE%20HYMN%20PREVIEW.mp3',
    displayName: 'Nee Cheranamule Hymn',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  // {
  //   path: 'https://firebasestorage.googleapis.com/v0/b/the-legacy-b3f2f.appspot.com/o/3.%20RANDI%20UTSAHINCHI%20INSTRUMENTAL%20PREVIEW.mp3?alt=media&token=b6d052b1-a614-4ee5-af53-67a96b3599d5',
  //   displayName: 'Randi Utsahinchi Instrumental',                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
  //   artist: 'Solomon Gollapalle',
  //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  // },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/4.%20HALLELUJAH%20STUTHI%20MAHIMA%20PREVIEW%20.mp3',
    displayName: 'Hallelujah Stuthi Mahima',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/5.%20INSTRUMENTAL%20BREAK%20%20PREVIEW%20.mp3',
    displayName: 'Instrumental Break',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/6.%20RANDI%20YEHOVANU%20GURCHI%20PREVIEW.mp3',
    displayName: 'Randi Yehova Gurchi',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  {
    path: 'https://keysforlifeproductions.b-cdn.net/7.%20DEVA%20NEE%20KROAKU%20NA%20PRANAMU%20PREVIEW.mp3',
    displayName: 'Deva Nee Koraku Na Pranamu',
    artist: 'Solomon Gollapalle',
    cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  },
  // {
  //   path: 'https://cdn.keysforlifeproductions.org/8.%20%20NINU%20ARADHINTHUMU%20YESIAH%20PREVIEW.mp3',
  //   displayName: 'Ninu Aradhinthumu Yesaiah',
  //   artist: 'Solomon Gollapalle',
  //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  // },
  // {
  //   path: 'https://cdn.keysforlifeproductions.org/9.%20YESU%20RAKTHAMU%20PREVIEW.mp3',
  //   displayName: 'Yesu Rakthamu',
  //   artist: 'Solomon Gollapalle',
  //   cover: "https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png",
  // }
  ] ;


  // Check if Playing
  let isPlaying = false;
  
  // Play
  function playSong() {
    isPlaying = true;
    playBtn.classList.replace('fa-play', 'fa-pause');
    playBtn.setAttribute('title', 'Pause');
    music.play();
    
  }
                                                                                                                                       /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
  // Pause
  function pauseSong() {
    isPlaying = false;
    playBtn.classList.replace('fa-pause', 'fa-play');
    playBtn.setAttribute('title', 'Play');
    music.pause();
  }
  
 
  // Play or Pause Event Listener
  playBtn.addEventListener('click', () => (isPlaying ? pauseSong() : playSong()));
  
  // Update DOM
  function loadSong(song) {
    title.textContent = song.displayName;
    artist.textContent = song.artist;
    music.src = song.path;                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
    changeCover(song.cover);

    var ua = navigator.userAgent;



    let isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    if(!isIOS) {
      window.navigator.mediaSession.metadata = new window.MediaMetadata({
        title: song.displayName,
        artist: song.artist,
        artwork: [
          {
            sizes:'256x256',
            src:song.cover,
            type:'image/jpeg',
          }
        ],
    });
    }
     
  }

 
  
  function changeCover(cover) {
    image.classList.remove('active');
    setTimeout(() => {
      image.src = cover;
      image.classList.add('active');
    }, 100)
 
  } 
  
  // Current Song
  let songIndex = 0;
  
  // Previous Song                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
  function prevSong() {
    songIndex--;
    if (songIndex < 0) {
      songIndex = songs.length - 1;
    }
    loadSong(songs[songIndex]);
    playSong();
  }
  
  // Next Song
  function nextSong() {
   
      songIndex++;
      console.log(songIndex)
      
    if (songIndex > songs.length - 1) {
      songIndex = 0;
    }                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
    loadSong(songs[songIndex]);
    playSong();
    }
    
  
  
  // On Load - Select First Song
  loadSong(songs[songIndex]);
  
  // Update Progress Bar & Time
  function updateProgressBar(e) {
    if (isPlaying) {
      const { duration, currentTime } = e.srcElement;
      // Update progress bar width
      const progressPercent = (currentTime / duration) * 100;
      progress.style.width = `${progressPercent}%`;
      // Calculate display for duration
      const durationMinutes = Math.floor(duration / 60);                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
      let durationSeconds = Math.floor(duration % 60);
      if (durationSeconds < 10) {
        durationSeconds = `0${durationSeconds}`;
      }
      // Delay switching duration Element to avoid NaN
      if (durationSeconds) {
        durationEl.textContent = `${durationMinutes}:${durationSeconds}`;
      }
      // Calculate display for currentTime                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
      const currentMinutes = Math.floor(currentTime / 60);
      let currentSeconds = Math.floor(currentTime % 60);
      if (currentSeconds < 10) {
        currentSeconds = `0${currentSeconds}`;
      }
      currentTimeEl.textContent = `${currentMinutes}:${currentSeconds}`;
    }                                                                                                                                     /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
  }
  
  
  // Set Progress Bar
  function setProgressBar(e) {
    const width = this.clientWidth;
    const clickX = e.offsetX;
    const { duration } = music;
    music.currentTime = (clickX / width) * duration;
  }

  // to set action handlers



 
                                                                                                                                       /* background: #2C303B;color: white;border: 3px solid #191B21;font-family: 'Montserrat', sans-serif;padding: 5px;margin: 5px;font-size: 27px;min-width: 70px;min-height: 50px;cursor: pointer;float: left;border-radius: 5px;outline: none;border-radius: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;}.input-container {overflow: hidden;padding: 20px;padding-top: 0;border: 15px solid #242632;border-top: 0;border-bottom: 0;padding-bottom: 50px;}*/
  // Event Listeners
  prevBtn.addEventListener('click', prevSong);
  nextBtn.addEventListener('click', nextSong);
  music.addEventListener('ended', nextSong);
  music.addEventListener('timeupdate', updateProgressBar);
  progressContainer.addEventListener('click', setProgressBar);
  creditsToggle.addEventListener('click',() => {
      if(this.credits) {
        document.getElementById('list').style.display = 'none'
        document.getElementById('credits').style.display = 'block'
       
        document.getElementById('back-index2').style.display = 'block'
        document.getElementById('next-index').style.display = 'none'
        this.credits = false
      
      } else {
        
        
        this.credits = true
      
      }
  });

  document.getElementById('back-index2').addEventListener('click', () => {
        document.getElementById('list').style.display = 'revert'
        document.getElementById('credits').style.display = 'none'
       
        document.getElementById('back-index2').style.display = 'none'
        document.getElementById('next-index').style.display = 'flex'
        this.credits = true 
  });

 [...listSong].forEach((element,index,array) => {
     element.addEventListener('click', () => {
       songIndex = index
        loadSong(songs[index]);
        playSong();
       
     })
 })

 document.getElementById('share-btn').addEventListener('click', () => {
  if (navigator.share) {
    navigator.share({
      title: 'The Legacy - By Solomon Gollapalle',
      text: 'Pre order the album now!!',
      url: 'https://app.keysforlifeproductions.org'
    }).then(() => {
      console.log('Thanks for sharing!');
    })
    .catch(console.error);
  } else {
    // fallback
  }
 })
  
  
 setTimeout(function(){
  document.querySelector(".splash").classList.add("seen");
  }, 1000);

    }

    
    
  
  render() {
  
    
    return (
        
      <div>     
      <div style={{
        
      }} >
       

      <div className="splash" >
   
  <div  
       style={{
         display:'flex',
         flexDirection:'column',
         alignItems:'center',
         justifyContent:'center',
         height:'80vh'
       }}
   className="heading">

  

  <h1  style={{paddingBottom:'2px',color:'#fff7f7',textShadow:"rgba(194, 182, 182, 0.788) 2px 2px"}}> 
    <span style={{color:"#3287a7f2"}}>THE</span>  <span>LEGACY</span></h1> 
    <span className="heading-tag">
      <p style={{textAlign:'center'}}>BY</p>  <p>SOLOMON GOLLPALLE</p>
    </span> <br/><br/> 
    </div>
    
    </div>


        </div>
      <div>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/all.min.css" />
        <div className="bg-container">
          <img src alt="" id="background" />
        </div>
        
        <div className="player-container">
          {/* Song */}
          <div className="img-container">
            <img src="https://res.cloudinary.com/lucidb/image/upload/v1607102849/Capture_ipaj5y.png" alt="" id="cover" className="active" />
            
          </div>
          

        
         
          <div id="back-index2" style={{display:'none'}}  className="back-player">
          <i style={{color:'#3287a7',paddingTop:'20px',paddingLeft:'20px',fontSize:'20px'}}  class="fas fa-chevron-left"></i>
          </div>
         
          <div className="sectionTop">
            <div id="next-index" className="next-index">
              
            <i style={{color:'rgba(75, 73, 73, 0.74)'}} class="fas fa-ellipsis-h"></i>
            </div>
            <div className="sectionTop-left">
              <Link to='/page4'><button  className="pre-button">PRE-ORDER</button></Link>
              <i id="share-btn" className="fas fa-share-alt"></i>
            
            </div>
            
          </div>
         
          </div>
         
         
          
           
          <div id="list" className="list">
            
          
              {
                this.state.songs.map((element,id) => 
                
                <ul>
                    <li className='listSong' key={id}>
                      
                      <div className="listItem">
                      {id==1 && <div>
                           <p style={{display:'block',whiteSpace:'pre-line',padding:15,fontStyle:'italic',fontWeight:'bold',borderColor:'black'}}>Preview Songs -</p>
                        </div>}
                      <div id="listSong" style={{width:'90%',padding:'10px',cursor:'pointer'}} >
                            <span style={{fontSize:'12px'}}>{element.displayName}</span>
                      </div>
                    
                           
                            <div className="clicks">
                        <i className="fas fa-compact-disc"></i> <span></span>
                        
                        </div>
                        </div>
                     
                        
                    </li>
                </ul> 
                  
                      )
              }
          </div>
          
          <div id="credits" style={{display:'none'}} className="list">
          <div style={{fontWeight:'bold'}} className="about">About Solomon Gollapalle</div> <br/>
          An Indian by birth, though born in a pastoral family, I saw a great need for Christians to be equipped in the area of worship. Our nation hasn’t seen even half of the worship training or tools as the western world. Since my early teens, I have been observing the difficulties faced by worshippers on this side of the globe where worship is restricted, as much of it was based on the cultural backgrounds. I felt a greater need to uplift and bring a revival in the worship style.

I struggled much to learn, train and acquire even notations for few hymns. As I grew older, the Lord laid it on my heart to open a University with accreditation as an academic school.

Envisioning a University in Hyderabad, South India, I, with great zeal and commitment have prayerfully focused on a project through which some of the resources for the school can be provided.
One of the basic purpose at The Legacy is to give the true worshippers, in spirit and truth, not just a platform to perform but to equip so they excel with the right God-given skills and talents to perfection. The worship school will be an academic and university acclaimed school.

This Worship Album is my unfiltered offering of unique skill and gratitude to God.
<br/>
I strongly believe,

and assure you that this worship album will revive your spirit and touch your hearts deeply with the message of God, connecting you to God, while transforming lives.
I request you to subscribe now and listen to the Album and be blessed. I appreciate your support and cannot wait for you to enjoy passionate musical experience that engages meditative moments of adoration, gratified praise and enthusiastic time for the listener.
I hope and pray that God would inspire you to pray and subscribe with the "The Legacy" project. <br></br>
'...So shall your God rejoice over you.' - Isaiah 62:5 <br/>
 ‘Vision without action is a dream. Action without vision is simply passing the time. Action with vision is making a positive difference. - Joel baker <br/>
May God richly bless you! God’s blessings
<br/>
<i>-Solomon Sumanth Gollapalle</i>
          </div>
            
          
          <div className="player">
         
          <audio src />
         
          {/* Controls */}
          <div  className="player-controls">
              <div style={{animation: 'moveSlideshow 15s linear infinite'}} className="meta">
                  <span style={{width:'120px',fontSize:'12px',letterSpacing:'.5px',display:'block',wordBreak:'break-word',marginBottom:'-15px'}} id="title" /> <br/> <div style={{height:'2px'}}></div>
                    <span style={{color:'#b3b5bb',fontSize:'10px'}} id="artist" />
              </div>
          <div className="controls">
         <div style={{marginRight:'-10px'}}><i  style={{fontSize:'12px',color:'#676b6d'}} className="fas fa-step-backward" id="prev" title="Previous" /></div> 
           <div style={{marginTop:'-6px'}}><i style={{fontSize:'18px'}} className="fas fa-play main-button" id="play" title="Play" /></div> 
          <div style={{marginLeft:'-10px'}}><i  style={{fontSize:'12px',color:'#676b6d'}} className="fas fa-step-forward" id="next" title="Next" /></div>  
        
          </div>
             {/* Progress */}
          <div className="progress-container" id="progress-container">
            <div className="progress" id="progress" />
            {/* <div className="duration-wrapper">
              <span id="current-time" />
              <span id="duration" />
            </div> */}
          </div>
          </div>
            </div>
          
        
      </div>
      <div>
      {/* <!-- Cloudflare Web Analytics --> */}
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "23705fbeb8ab49de99a810e945bd1fd8"}'>
      </script>
      {/* <!-- End Cloudflare Web Analytics --> */}
      </div>
      </div>
    )
  }
}
